@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import 'bootstrap/scss/_reboot.scss';
@import 'bootstrap/scss/_type.scss';
@import 'bootstrap/scss/_grid.scss';
@import 'bootstrap/scss/_utilities.scss';

body {
    background-color: var(--neutral-theme) !important;
}
